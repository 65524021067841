<template>
    <el-row class="tac">
        <el-col :span="24">
            <el-menu mode="horizontal" :ellipsis="false" default-active="0" style="height: 50px;"
                :collapse-transition="false">
                <el-menu-item index="0" @click="drawer = true" v-if="nickName">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path
                            d="M21 17.9995V19.9995H3V17.9995H21ZM6.59619 3.90332L8.01041 5.31753L4.82843 8.49951L8.01041 11.6815L6.59619 13.0957L2 8.49951L6.59619 3.90332ZM21 10.9995V12.9995H12V10.9995H21ZM21 3.99951V5.99951H12V3.99951H21Z"
                            fill="rgba(0,0,0,1)"></path>
                    </svg>
                </el-menu-item>
                <div class="flex-grow" />
                <el-menu-item index="0">
                    <router-link to="/login">{{ nickName == null ? '岩月' : nickName }}</router-link>
                </el-menu-item>
            </el-menu>
        </el-col>
    </el-row>
    <div style="text-align: left;margin: 10px 20px;">
        <router-view></router-view>
        <el-dialog v-model="choosedNameInfo.show" :title="'功能介绍 - ' + choosedNameInfo.name" width="90%">
            <div>
                <span>更新时间：{{ choosedNameInfo.time }}</span>
            </div>
            <hr />
            <div v-html="choosedNameInfo.description.split('\n').join('<br/>')"></div>
        </el-dialog>
    </div>
    <el-drawer v-model="drawer" title="I am the title" :with-header="false" direction="ltr" size="70%">
        <el-menu class="dou-menu" text-color="black" :collapse-transition="false">
            <el-menu-item index="1" @click="toggleMenu">
                <router-link to="/">
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001C20 20.5524 19.5523 21.0001 19 21.0001ZM6 19.0001H18V9.15757L12 3.70302L6 9.15757V19.0001Z"
                                fill="rgba(0,0,0,1)"></path>
                        </svg>
                    </el-icon>
                    <span>首页</span>
                </router-link>
            </el-menu-item>
            <el-sub-menu index="4">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path
                                d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM8 9V11H16V9H8ZM8 13V15H16V13H8Z">
                            </path>
                        </svg>
                    </el-icon>
                    <span>资金管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="4-1" @click="toggleMenu">
                        <router-link to="/bill">收支管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="4-2" @click="toggleMenu">
                        <router-link to="/pl/cashcard">储蓄卡管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="4-3" @click="toggleMenu">
                        <router-link to="/pl/creditcard">信用卡管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="4-4" @click="toggleMenu">
                        <router-link to="/pl/borrow">借贷管理</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="1">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M17 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9V3H15V1H17V3ZM4 9V19H20V9H4ZM6 11H8V13H6V11ZM6 15H8V17H6V15ZM10 11H18V13H10V11ZM10 15H15V17H10V15Z"
                                fill="rgba(0,0,0,1)"></path>
                        </svg>
                    </el-icon>
                    <span>时间安排</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="1-1" @click="toggleMenu">
                        <router-link to="/pl/plan">计划管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="1-2" @click="toggleMenu">
                        <router-link to="/list?e=thing">事件管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="1-3" @click="toggleMenu">
                        <router-link to="/list/Todo">待做列表</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="5">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path
                                d="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM17.3628 15.2332C20.4482 16.0217 22.7679 18.7235 22.9836 22H20C20 19.3902 19.0002 17.0139 17.3628 15.2332ZM15.3401 12.9569C16.9728 11.4922 18 9.36607 18 7C18 5.58266 17.6314 4.25141 16.9849 3.09687C19.2753 3.55397 21 5.57465 21 8C21 10.7625 18.7625 13 16 13C15.7763 13 15.556 12.9853 15.3401 12.9569Z">
                            </path>
                        </svg>
                    </el-icon>
                    <span>社交</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="5-1" @click="toggleMenu">
                        <router-link to="/list?e=plan">通讯录</router-link>
                    </el-menu-item>
                    <el-menu-item index="5-2" @click="toggleMenu">
                        <router-link to="/list?e=thing">生活圈</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="3">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M3 3H11V11H3V3ZM3 13H11V21H3V13ZM13 3H21V11H13V3ZM13 13H21V21H13V13Z"></path>
                        </svg>
                    </el-icon>
                    <span>功能列表</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="3-1" @click="toggleMenu">
                        <router-link to="/pl/account">账号管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="3-2" @click="toggleMenu">
                        <router-link to="/NoteList">笔记</router-link>
                    </el-menu-item>
                    <el-menu-item index="3-20" @click="toggleMenu">
                        <router-link to="/Test">测试</router-link>
                    </el-menu-item>
                    <el-menu-item index="3-30" @click="toggleMenu">
                        <router-link to="/NoteSave">测试</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="2">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M9.95401 2.2106C11.2876 1.93144 12.6807 1.92263 14.0449 2.20785C14.2219 3.3674 14.9048 4.43892 15.9997 5.07103C17.0945 5.70313 18.364 5.75884 19.4566 5.3323C20.3858 6.37118 21.0747 7.58203 21.4997 8.87652C20.5852 9.60958 19.9997 10.736 19.9997 11.9992C19.9997 13.2632 20.5859 14.3902 21.5013 15.1232C21.29 15.7636 21.0104 16.3922 20.6599 16.9992C20.3094 17.6063 19.9049 18.1627 19.4559 18.6659C18.3634 18.2396 17.0943 18.2955 15.9997 18.9274C14.9057 19.559 14.223 20.6294 14.0453 21.7879C12.7118 22.067 11.3187 22.0758 9.95443 21.7906C9.77748 20.6311 9.09451 19.5595 7.99967 18.9274C6.90484 18.2953 5.63539 18.2396 4.54272 18.6662C3.61357 17.6273 2.92466 16.4164 2.49964 15.1219C3.41412 14.3889 3.99968 13.2624 3.99968 11.9992C3.99968 10.7353 3.41344 9.60827 2.49805 8.87524C2.70933 8.23482 2.98894 7.60629 3.33942 6.99923C3.68991 6.39217 4.09443 5.83576 4.54341 5.33257C5.63593 5.75881 6.90507 5.703 7.99967 5.07103C9.09364 4.43942 9.7764 3.3691 9.95401 2.2106ZM11.9997 14.9992C13.6565 14.9992 14.9997 13.6561 14.9997 11.9992C14.9997 10.3424 13.6565 8.99923 11.9997 8.99923C10.3428 8.99923 8.99967 10.3424 8.99967 11.9992C8.99967 13.6561 10.3428 14.9992 11.9997 14.9992Z">
                            </path>
                        </svg>
                    </el-icon>
                    <span>系统管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="2-1" @click="toggleMenu">
                        <router-link to="/pl/note">笔记</router-link>
                    </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                    <el-menu-item index="2-2" @click="toggleMenu">
                        <router-link to="/updateLog">系统优化</router-link>
                    </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                    <el-menu-item index="2-3" @click="toggleMenu">
                        <router-link to="/updateLog">日志</router-link>
                    </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                    <el-menu-item index="2-4" @click="toggleMenu">
                        <router-link to="/user-manual">使用手册</router-link>
                    </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                    <el-menu-item index="2-5" @click="toggleMenu">
                        <router-link to="/sys-setting">设置</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="101" v-if="getUser && getUser.role && getUser.role.includes('Admin')">
                <template #title>
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M9.95401 2.2106C11.2876 1.93144 12.6807 1.92263 14.0449 2.20785C14.2219 3.3674 14.9048 4.43892 15.9997 5.07103C17.0945 5.70313 18.364 5.75884 19.4566 5.3323C20.3858 6.37118 21.0747 7.58203 21.4997 8.87652C20.5852 9.60958 19.9997 10.736 19.9997 11.9992C19.9997 13.2632 20.5859 14.3902 21.5013 15.1232C21.29 15.7636 21.0104 16.3922 20.6599 16.9992C20.3094 17.6063 19.9049 18.1627 19.4559 18.6659C18.3634 18.2396 17.0943 18.2955 15.9997 18.9274C14.9057 19.559 14.223 20.6294 14.0453 21.7879C12.7118 22.067 11.3187 22.0758 9.95443 21.7906C9.77748 20.6311 9.09451 19.5595 7.99967 18.9274C6.90484 18.2953 5.63539 18.2396 4.54272 18.6662C3.61357 17.6273 2.92466 16.4164 2.49964 15.1219C3.41412 14.3889 3.99968 13.2624 3.99968 11.9992C3.99968 10.7353 3.41344 9.60827 2.49805 8.87524C2.70933 8.23482 2.98894 7.60629 3.33942 6.99923C3.68991 6.39217 4.09443 5.83576 4.54341 5.33257C5.63593 5.75881 6.90507 5.703 7.99967 5.07103C9.09364 4.43942 9.7764 3.3691 9.95401 2.2106ZM11.9997 14.9992C13.6565 14.9992 14.9997 13.6561 14.9997 11.9992C14.9997 10.3424 13.6565 8.99923 11.9997 8.99923C10.3428 8.99923 8.99967 10.3424 8.99967 11.9992C8.99967 13.6561 10.3428 14.9992 11.9997 14.9992Z">
                            </path>
                        </svg>
                    </el-icon>
                    <span>网站管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="101-1" @click="toggleMenu">
                        <router-link to="/admin/pl/pagefeature">功能介绍</router-link>
                    </el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
        </el-menu>
    </el-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import api from '../../api/api.js';
import dayjs from 'dayjs';
export default {
    data() {
        return {
            drawer: false,
            choosedNameInfo: {
                show: false,
                name: "",
                description: null
            }
        }
    },
    computed: {
        ...mapGetters('user', ['getUser']),
        nickName() {
            // 如果需要显示用户名，可以从用户对象中获取
            return this.getUser ? (this.getUser.nickName ? this.getUser.nickName : "") : null;
        }
    },
    mounted() {
        this.bindEvents();
        this.$router.afterEach(() => {
            this.$nextTick(() => {
                this.unbindEvents();
                this.bindEvents();
            });
        });
    },
    methods: {
        handleSelect(index) {
            console.log(index);
        },
        toggleMenu() {
            let self = this;
            self.drawer = !self.drawer;
        },
        bindEvents() {
            const buttons = document.querySelectorAll('.dou-badge');
            buttons.forEach(button => {
                button.addEventListener('click', this.handleClick);
            });
        },
        unbindEvents() {
            const buttons = document.querySelectorAll('.dou-badge');
            buttons.forEach(button => {
                button.removeEventListener('click', this.handleClick);
            });
        },
        async handleClick(event) {
            let tempPath = this.$route.path;
            let tempName = event.target.innerText;
            var resopnse = await api.GetPageFeature(tempPath, tempName);
            if (resopnse != '') {
                this.choosedNameInfo.name = tempName;
                this.choosedNameInfo.description = resopnse.description;
                let tempTime = dayjs(resopnse.time + "Z").format("YYYY-MM-DD HH:mm:ss");
                this.choosedNameInfo.time = tempTime;
                this.choosedNameInfo.show = true;
            }
        }
    }
}
</script>

<style>
/* .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: white !important;
} */

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
    color: black !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: white !important;
}

.flex-grow {
    flex-grow: 1;
}

a {
    text-decoration: none;
    color: black;
}

.el-menu-item a {
    width: 100%;
    text-align: left;
}
</style>