<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="entityName" suffixStr="详情"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="基础" name="first">
            <GenericInfo :entityName="entityName" :version="version" :id="id" type="p"></GenericInfo>
        </el-tab-pane>
        <el-tab-pane label="附加" name="second" v-if="showMore">
            <component :is="currentComponent" @updateEvent="handleUpdate" :entityName="entityName" :id="id"></component>
        </el-tab-pane>
    </el-tabs>
    <el-row justify="center" style="margin-top: 20px;"
        v-if="(entityObj.update == true || entityObj.delete == true) && activeName == 'first'">
        <el-button v-if="entityObj.update" type="primary" @click="edit">编辑</el-button>
        <el-button v-if="entityObj.delete" type="danger" @click="del">删除</el-button>
    </el-row>
</template>
<script>
import { mapState } from 'vuex';
import api from '../../../api/api.js';
import GenericInfo from './GenericInfo.vue';
import { ElMessageBox } from 'element-plus';
import { ElMessage } from 'element-plus';
import CashCardInfo from './../../Business/Bill/CashCardInfo.vue';//储蓄卡详情
import CreditCardInfo from './../../Business/Bill/CreditCardInfo.vue';//信用卡详情
import AccountInfo from './../../Business/Account/AccountInfo.vue';//账户详情
import PlanInfo from './../../Business/Diary/PlanInfo.vue';//计划详情
import GoalInfo from './../../Business/Diary/GoalInfo.vue';//目标详情
import GoalExecutionInfo from './../../Business/Diary/GoalExecutionInfo.vue';//目标详情
export default {
    props: {
        entityName: {
            type: String,
            required: true
        }
        , id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            entityObj: {},
            activeName: "first",
            attrs: [],
            info: {},
            currentComponent: "",//动态组件
            showMore: true,//是否显示附加内容
            version: 1
        }
    },
    created() {
        this.entityObj = this.douTable[this.entityName];
        this.updateExt();
    },
    watch: {
        entityName(newValue) {
            this.entityObj = this.douTable[newValue];
            this.updateExt();
        },
        childUpdateTab(newVal) {
            if (newVal == true) {
                this.activeName = "first";
                this.$store.commit('generic/SET_CHILDUPDATETAB', false);
            }
        }
    },
    mounted() {
    },
    computed: {
        ...mapState('user', ['douTable']),
        ...mapState('generic', ['childUpdateTab'])
    },
    components: {
        GenericInfo,
        CashCardInfo,
        CreditCardInfo,
        AccountInfo,
        PlanInfo,
        GoalInfo,
        GoalExecutionInfo
    },
    methods: {
        updateExt() {
            switch (this.entityObj.entityName) {
                case "CashCard":
                case "CreditCard":
                case "Plan":
                case "Goal":
                case "GoalExecution":
                case "Account": {
                    this.currentComponent = this.entityObj.entityName + "Info";
                    this.showMore = true;
                } break;
                default: {
                    this.showMore = false;
                    this.activeName = "first";
                } break;
            }
        },
        edit() {
            switch (this.entityName) {
                case "bill": {
                    this.$router.push({ path: "/Bill/Save/" + this.id });
                } break;
                default: {
                    var layoutStr = this.$router.currentRoute._value.path.split('/')[1];
                    var path = "/ps/" + this.entityName + "/" + this.id;
                    if (layoutStr != "pi") {
                        path = "/" + layoutStr + "/ps/" + this.entityName + "/" + this.id;
                    }
                    this.$router.push({ path:path});
                } break;
            }

        },
        del() {
            var message = "删除后不可撤销，是否删除?";
            switch (this.entityObj.entityName) {
                case "Plan": {
                    message = "删除后不可撤销，且会删除所有子计划,目标以及执行记录，是否删除？";
                } break;
                case "Goal": {
                    message = "删除后不可撤销，且会删除所有执行记录，是否删除？";
                } break;
            }
            ElMessageBox.confirm(message, '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var result = false;
                switch (this.entityObj.entityName) {
                    case "Bill": {
                        result = await api.BillDelete(this.id);
                    } break;
                    case "AmountAdjust": {
                        result = await api.CardAdjustDelete(this.id);
                    } break;
                    case "Plan": {
                        result = await api.DeletePlan(this.id);
                    } break;
                    case "Goal": {
                        result = await api.DeleteGoal(this.id);
                    } break;
                    default: {
                        result = await api.DeleteGenericDataById(this.entityObj.entityName, this.id);
                    } break;
                }
                if (result) {
                    ElMessage({
                        message: '删除成功！',
                        type: 'success',
                    })
                    window.history.go(-1);
                } else {
                    ElMessage.error('删除失败，请重试！')
                }
            }).catch(() => {

            });
        },
        handleUpdate(obj) {
            switch (this.entityObj.entityName) {
                case "CashCard":
                case "CreditCard":
                case "GoalExecution":
                case "Account": {
                    if (obj == true) {
                        this.version += 1;
                    }
                } break;
            }
        }
    }
}
</script>